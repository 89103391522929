import type Axios from "axios";
import {axiosInstance} from "../../api";
import CartService from "../../cart/service/CartService";
import ProposalService from "../service/proposalService";
import type {WatchList} from "../../watchlist/models";
import productService, {DetailedProduct} from "../../service/productService";
import type {PartAmounts, Proposal, SparePositionType} from "../model";

class WatchListConsumer {
	api: Axios;

	constructor(api: Axios) {
		this.api = api;
	}

	async consumeParts(proposalId, dmsOrderNumber, parts: PartAmounts): Promise<Proposal> {
		const detailedParts: DetailedProduct[] = await this.fetchEnhancedParts(Object.keys(parts));
		const sparePositions: SparePositionType[] = detailedParts.map(part => {
			return {
				productNumber: part.partNumber,
				name: part.product.name,
				unitPrice: part.detail.storage.retailPrice.value || 0,
				retailPrice: part.detail.storage.retailPrice.value || 0,
				amount: parts[part.partNumber],
				unit: "CHUNK"
			};
		});
		return ProposalService.addSpares(proposalId, sparePositions);
	}

	async consumeWatchList(proposalId: string, dmsOrderNumber: string, watchListName: string): Promise<Proposal> {
		const watchList: WatchList = await CartService.getWatchList(watchListName, "WATCHLIST");
		const items = watchList.cartItems;
		const itemsObject = items.reduce(
			(previousValue, currentValue) => {
				previousValue[currentValue.partNumber] = currentValue.amount;
				return previousValue;
			}, {}
		);
		return this.consumeParts(proposalId, dmsOrderNumber, itemsObject);
	}

	async fetchEnhancedParts(parts: string[]): Promise<Array<DetailedProduct>> {
		return productService.getDetailedProducts(parts, false, true, false);
	};
}

const WatchListConsumerInstance = new WatchListConsumer(axiosInstance);
export default WatchListConsumerInstance;
