import {applyMiddleware, compose, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import {rootEpic, rootReducer} from "./reducers/rootReducer";
import {tracker} from "./middleware/tracker";
import apiMiddleware from "./middleware/apiMiddleware";
import {notificator} from "./middleware/notificator";
import {composeWithDevTools} from "redux-devtools-extension";
import {createBrowserHistory} from "history";
import {routerMiddleware} from "connected-react-router";
import apiTokenMiddleware from "./middleware/apiTokenMiddleware";
import createDebounce from "redux-debounced";
import {dealerInitializer} from "./middleware/dealerInitializer";
import cartInitializer from "./middleware/cartInitializer";
import {SET_LANGUAGE} from "./i18n/i18n";
import i18next from "i18next";
import {createEpicMiddleware} from "redux-observable";
import {sseSubscriptionRefresher} from "./middleware/sseSubscriptionRefresher";
import {dmsMiddleware} from "./middleware/dmsMiddleware";

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware();

function configureStore() {
	let store;
	const middleWares = [
		createDebounce(),
		thunkMiddleware,
		apiMiddleware,
		tracker,
		notificator,
		routerMiddleware(history),
		apiTokenMiddleware,
		dealerInitializer,
		dmsMiddleware,
		cartInitializer,
		epicMiddleware,
		sseSubscriptionRefresher
	];
	if (process.env.NODE_ENV !== "production") {
		store = createStore(rootReducer(history), composeWithDevTools(applyMiddleware(...middleWares)));
	} else {
		store = createStore(rootReducer(history), compose(applyMiddleware(...middleWares)));
	}
	if (module.hot) {
		module.hot.accept("./reducers/rootReducer", () => {
			store.replaceReducer(rootReducer(history));
		});
	}
	store.dispatch({
		type: SET_LANGUAGE,
		payload: i18next.language
	});

	epicMiddleware.run(rootEpic);

	return store;
}

export default configureStore();
