import type Axios, {AxiosPromise} from "axios";
import {axiosInstance} from "../../api";
import type {CreateProposalFormState} from "../component/CreateProposalForm";
import type {
	ConsumablePositionType, Proposal,
	ProposalFilter,
	ProposalPagination,
	SparePositionType,
	TaskPositionType,
	UpdateDiscountsRequest
} from "../model";
import type {PrintOptions} from "../component/ProposalPrintDialog";
import paramsSerializer from "../../service/paramsSerializer";

class ProposalService {
	api: Axios;

	constructor(api: Axios) {
		this.api = api;
	}

	async createProposal(createProposalForm: CreateProposalFormState): Promise<Proposal> {
		const {
			vin,
			salutation,
			name,
			address,
			city,
			zipCode,
			phone,
			email,
			vehicleRegistrationNumber,
			hourlyRate,
			dmsOrderNumber,
			vehicleMileage
		} = createProposalForm;

		return this.api.post("/proposal/my", {
			vehicle: vin,
			dmsOrderNumber,
			hourlyRate,
			customer: {
				salutation,
				name,
				address,
				city,
				zipCode,
				phone,
				email,
				vehicleRegistrationNumber,
				vehicleMileage,
			}
		}).then(response => response.data);
	}

	async editCustomer(editProposalForm: CreateProposalFormState, aggregate: string): Promise<Proposal> {
		const {
			salutation,
			name,
			address,
			city,
			zipCode,
			phone,
			email,
			vehicleRegistrationNumber,
			vehicleMileage,
		} = editProposalForm;

		return this.api.patch(`/proposal/my/${aggregate}/customer`, {
			customer: {
				salutation,
				name,
				address,
				city,
				zipCode,
				phone,
				email,
				vehicleRegistrationNumber,
				vehicleMileage,
			},
		}).then(response => response.data);
	}

	async editDiscounts(request: UpdateDiscountsRequest, aggregate: string): Promise<Proposal> {
		return this.api.patch(`/proposal/my/${aggregate}/discount`, {
			...request
		}).then(response => response.data);
	}

	async fetchProposals(): Promise<Proposal[]> {
		return this.api.get("/proposal/my").then(response => response.data);
	}

	async fetchProposal(id: string): Promise<Proposal> {
		return this.api.get(`/proposal/my/${id}`).then(response => response.data);
	}

	async searchProposal(searchRequest: ProposalFilter, pagination: ProposalPagination): Promise<Proposal[]> {
		const sort = pagination.sort.map(entry => `${Object.keys(entry)[0]},${Object.values(entry)[0]}`);
		const {page, size} = pagination;
		return this.api.get("/proposal/my/search",
			{
				params: {
					...searchRequest,
					page,
					size,
					sort
				},
				paramsSerializer
			},
		).then(response => response.data);
	}

	async searchLinkedProposals(orderNumbers: string[]): Promise<Proposal[]> {
		return this.api.post("/proposal/my/byOrders",
			{
				orderNumbers
			}
		).then(response => response.data);
	}

	async finishProposal(id: string): Promise<Proposal> {
		return this.api.post(`/proposal/my/${id}/finish`).then(response => response.data);
	}

	async deleteProposal(id: string): Promise<void> {
		return this.api.delete(`/proposal/my/${id}`).then(response => response.data);
	}

	async copyProposal(id): Promise<Proposal> {
		return this.api.post(`/proposal/my/${id}/copy`).then(response => response.data);
	}

	async printProposal(id: string, options: PrintOptions): AxiosPromise {
		return this.api.get(`/proposal/my/${id}/document`, {
			responseType: "arraybuffer",
			params: {...options}
		});
	}

	async addTask(aggregateId: string, task: TaskPositionType): Promise<Proposal> {
		return this.api.post(`proposal/my/${aggregateId}/task`, {
			...task
		}).then(response => response.data);

	}

	async addTasks(aggregateId: string, taskPositions: TaskPositionType[]): Promise<Proposal> {
		return this.api
			.post(`proposal/my/${aggregateId}/tasks`, taskPositions)
			.then(response => response.data);
	}

	async addConsumable(aggregateId: string, consumable: ConsumablePositionType): Promise<Proposal> {
		const {name, amount, unitPrice} = consumable;
		return this.api.post(`proposal/my/${aggregateId}/consumable`, {
			name,
			amount,
			unitPrice
		}).then(response => response.data);

	}

	async updateConsumable(aggregateId: string, consumable: ConsumablePositionType): Promise<Proposal> {
		const {name, amount, unitPrice} = consumable;
		return this.api.patch(`proposal/my/${aggregateId}/consumable/${name}`, {
			name,
			amount,
			unitPrice
		}).then(response => response.data);

	}

	async updateTask(aggregateId: string, task: TaskPositionType): Promise<Proposal> {
		return this.api.patch(`proposal/my/${aggregateId}/task`, {
			...task
		}).then(response => response.data);

	}

	async removeTask(aggregateId: string, taskId: number): Promise<Proposal> {
		return this.api.delete(`proposal/my/${aggregateId}/task`,
			{params: {taskId: taskId}}
		).then(response => response.data);

	}

	async removeSpare(aggregateId: string, productNumber: string): Promise<Proposal> {
		return this.api.delete(`proposal/my/${aggregateId}/spare/${productNumber}`, {}).then(response => response.data);

	}

	async removeConsumable(aggregateId: string, name: string): Promise<Proposal> {
		return this.api.delete(`proposal/my/${aggregateId}/consumable/${name}`, {}).then(response => response.data);

	}

	async addSpare(aggregateId: string, sparePosition: SparePositionType): Promise<Proposal> {
		return this.api.post(`proposal/my/${aggregateId}/spare`, {
			...sparePosition
		}).then(response => response.data);

	}

	async addSpares(aggregateId: string, sparePositions: SparePositionType[]): Promise<Proposal> {
		return this.api
			.post(`proposal/my/${aggregateId}/spares`, sparePositions)
			.then(response => response.data);

	}

	async updateSparePosition(aggregateId: string, sparePosition: SparePositionType): Promise<Proposal> {
		const {unitPrice, amount} = sparePosition;
		return this.api.patch(`proposal/my/${aggregateId}/spare/${sparePosition.productNumber}`, {
			unitPrice,
			amount
		}).then(response => response.data);

	}

	async updateValidityForProposal(aggregateId: string, validFrom: Date, validUntil: Date): Promise<Proposal> {
		return this.api.patch(`proposal/my/${aggregateId}/validity`, {
			validFrom,
			validUntil
		}).then(response => response.data);

	}

	async acceptProposal(id: string): Promise<Proposal> {
		return this.api.post(`/proposal/my/${id}/accept`).then(response => response.data);

	};

	async rejectProposal(id: string): Promise<Proposal> {
		return this.api.post(`/proposal/my/${id}/reject`).then(response => response.data);

	};
}

const ProposalServiceInstance = new ProposalService(axiosInstance);
export default ProposalServiceInstance;
