import type {EventHandler, EventType, OrdersFoundEvent} from "../index";
import store from "../../../configureStore";
import {DMS_ORDERS} from "../../action";

export const OrdersFoundEventHandler: EventHandler = {
	handles(eventType: EventType): boolean {
		return eventType === "ORDERS_FOUND";
	},
	handle(event: OrdersFoundEvent) {
		store.dispatch({
			type: DMS_ORDERS.FOUND,
			payload: event.orders.map(
				order => {
					return {
						orderNumber: order.orderId,
						createdAt: order.createdAt,
						customer: {
							customerNumber: order.customer.customerNumber,
							salutation: order.customer.salutation,
							firstName: order.customer.firstName,
							lastName: order.customer.lastName,
							city: order.customer.city,
							street: order.customer.street,
							zipCode: order.customer.zipCode,
							mobile: order.customer.mobile,
							phone: order.customer.phone,
							email: order.customer.email,
							country: order.customer.country,
						},
						vehicle: {
							customer: order.vehicle.customer,
							mileage: order.vehicle.mileage,
							vin: order.vehicle.vin,
							manufacturerCodeNumber: order.vehicle.manufacturerCodeNumber,
							typeCodeNumber: order.vehicle.typeCodeNumber,
							licensePlate: order.vehicle.licensePlate,
							emissionCode: order.vehicle.emissionCode,
							mainInspectionDate: order.vehicle.mainInspectionDate,
						},
						parts: order.parts?.map(part => {
							return {
								partNumber: part.partNumber,
								quantity: part.quantity,
							};
						}),
						state: order.status
					};
				}
			)});
	}
};
