import {DMS_ERROR, DMS_ORDERS} from "../../dms/action";
import type {DmsCustomer, DmsVehicle} from "../../dms/event/model/vehicle";
import {SEARCH_LINKED_PROPOSALS} from "../action";
import type {DmsPart} from "../../dms/event/model/dmsOrders";

export type DmsOrder = {
	orderNumber: string,
	createdAt: string,
	customer: DmsCustomer,
	vehicle: DmsVehicle,
	parts: DmsPart[],
	state: string
}

export type DmsOrdersState = {
	orders: DmsOrder[],
	isFetchingOrders: boolean,
	isFetchingLinkedProposals: boolean,
	failedToFetchLinkedProposals: boolean,
	linkedProposals: { [dmsOrderNumber: string]: string },
}

const initialState: DmsOrdersState = {
	orders: [],
	isFetchingOrders: false,
	isFetchingLinkedProposals: false,
	failedToFetchLinkedProposals: false,
	linkedProposals: {}
};

export const dmsOrders = (state = initialState, action) => {
	switch (action.type) {
		case DMS_ERROR:
			return {
				...state,
				isFetchingOrders: false,
				orders: []
			};
		case DMS_ORDERS.REQUEST:
			return {
				...state,
				isFetchingOrders: true
			};
		case DMS_ORDERS.FOUND:
			return {
				...state,
				orders: action.payload,
				isFetchingOrders: false
			};
		case SEARCH_LINKED_PROPOSALS.REQUEST:
			return {
				...state,
				isFetchingLinkedProposals: true,
				failedToFetchLinkedProposals: false,
			};
		case SEARCH_LINKED_PROPOSALS.SUCCESS:
			return {
				...state,
				isFetchingLinkedProposals: false,
				failedToFetchLinkedProposals: false,
				linkedProposals: action.payload
			};
		case SEARCH_LINKED_PROPOSALS.FAILURE:
			return {
				...state,
				isFetchingLinkedProposals: false,
				failedToFetchLinkedProposals: true,
				linkedProposals: []
			};
		default:
			return state;
	}
};
