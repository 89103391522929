import {ADD_SPARE_POSITIONS, CONSUME_WATCHLIST, REMOVE_SPARE_POSITION, UPDATE_SPARE_POSITION} from "../proposal/action";
import type {SparePositionType} from "../proposal/model";
import {addPartsToOrder, addWatchListItemsToOrder, deletePartFromOrder, updatePartFromOrder} from "../dms/action";

export const dmsMiddleware = store => next => action => {
	switch (action.type) {
		case ADD_SPARE_POSITIONS.SUCCESS:
			action.spares.forEach((spare: SparePositionType) =>
				store.dispatch(
					addPartsToOrder(
						store.getState().proposal.current.dmsOrderNumber,
						action.spares.map((spare: SparePositionType) => {
							return {
								partNumber: spare.productNumber,
								quantity: spare.amount
							};
						})
					)
				)
			);
			break;
		case CONSUME_WATCHLIST.SUCCESS:
			store.dispatch(
				addWatchListItemsToOrder(
					store.getState().proposal.current.dmsOrderNumber,
					action.watchListName
				)
			);
			break;
		case UPDATE_SPARE_POSITION.SUCCESS:
			store.dispatch(
				updatePartFromOrder(
					store.getState().proposal.current.dmsOrderNumber,
					action.partNumber,
					action.quantity
				)
			);
			break;
		case REMOVE_SPARE_POSITION.SUCCESS:
			store.dispatch(
				deletePartFromOrder(
					store.getState().proposal.current.dmsOrderNumber,
					action.partNumber
				)
			);
			break;
		default:
			break;
	}
	return next(action);
};
