import {Axios} from "axios";
import {axiosDmsInstance} from "../../api";
import type {DmsPart} from "../event/model/dmsOrders";


class DmsService {
	axios: Axios;

	constructor() {
		this.axios = axiosDmsInstance;
	}

	async getOrders(): Promise<void> {
		return this.axios.get("/order");
	}

	async getLocalStock(partNumbers: string[]): Promise<void> {
		return this.axios.post("/products/stock", {partNumbers});
	}

	async getVehicleByVin(vin: string): Promise<void> {
		return this.axios.get(`/vehicle/vin/${vin}`);
	}

	async searchVehiclesByLicensePlate(licensePlate: string): Promise<void> {
		return this.axios.post("/vehicle/licensePlate", {licensePlate});
	}

	async searchVehiclesByCustomerName(name: string): Promise<void> {
		return this.axios.post("/vehicle/customer/name", {name});
	}

	async searchVehiclesByCustomerEmail(email: string): Promise<void> {
		return this.axios.post("/vehicle/customer/email", {email});
	}

	async searchVehiclesByCustomerPhone(phone: string): Promise<void> {
		return this.axios.post("/vehicle/customer/phone", {phone});
	}

	async addPartToOrder(orderId: string, partNumber: string, quantity: number): Promise<void> {
		return this.axios.post(`/order/${orderId}/part`,
			{
				partNumber,
				quantity
			}
		);
	}

	async addPartsToOrder(orderId: string, parts: DmsPart[]): Promise<void> {
		return this.axios.post(`/order/${orderId}/part/bulk`,
			{
				parts
			}
		);
	}

	async updatePartFromOrder(orderId: string, partNumber: string, quantity: number): Promise<void> {
		return this.axios.patch(`/order/${orderId}/part`,
			{
				partNumber,
				quantity
			}
		);
	}

	async deletePartFromOrder(orderId: string, partNumber: string): Promise<void> {
		return this.axios.delete(`/order/${orderId}/part/${partNumber}`);
	}
}

const DmsServiceInstance = new DmsService();
export default DmsServiceInstance;
