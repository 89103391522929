import {ThunkAction} from "redux-thunk";
import type {StockInformation} from "../event/model/stockInformation";
import dmsCommunicationService from "../service/dmsCommunicationService";
import CartService from "../../cart/service/CartService";
import type {DmsPart} from "../event/model/dmsOrders";

export const DMS_ERROR = "DMS_ERROR";
export const DMS_ORDERS = {
	REQUEST: "DMS_ORDERS_REQUEST",
	FOUND: "DMS_ORDERS_FOUND",
	NOT_FOUND: "DMS_ORDERS_NOT_FOUND",
};
export const VEHICLE = {
	REQUEST: "CUSTOMERS_AND_VEHICLES_REQUEST",
	FOUND: "CUSTOMERS_AND_VEHICLES_FOUND",
	NOT_FOUND: "CUSTOMERS_AND_VEHICLES_NOT_FOUND",
};

export const STOCK_INFORMATION = {
	REQUEST: "STOCK_INFORMATION_REQUEST",
	FOUND: "STOCK_INFORMATION_FOUND",
	NOT_FOUND: "STOCK_INFORMATION_NOT_FOUND",
};

export const DMS_ORDER_ADD_PART = {
	REQUEST: "DMS_ORDER_ADD_PART_REQUEST",
	SUCCESS: "DMS_ORDER_ADD_PART_SUCCESS",
	ERROR: "DMS_ORDER_ADD_PART_ERROR",
};

export const DMS_ORDER_UPDATE_PART = {
	REQUEST: "DMS_ORDER_UPDATE_PART_REQUEST",
	SUCCESS: "DMS_ORDER_UPDATE_PART_SUCCESS",
	ERROR: "DMS_ORDER_UPDATE_PART_ERROR",
};

export const DMS_ORDER_ADD_PARTS = {
	REQUEST: "DMS_ORDER_ADD_PARTS_REQUEST",
	SUCCESS: "DMS_ORDER_ADD_PARTS_SUCCESS",
	ERROR: "DMS_ORDER_ADD_PARTS_ERROR",
};

export const DMS_ORDER_DELETE_PART = {
	REQUEST: "DMS_ORDER_DELETE_PART_REQUEST",
	SUCCESS: "DMS_ORDER_DELETE_PART_SUCCESS",
	ERROR: "DMS_ORDER_DELETE_PART_ERROR",
};

export type StockInformationRequestAction = { type: typeof STOCK_INFORMATION.REQUEST }
export type StockInformationSuccessAction = { type: typeof STOCK_INFORMATION.FOUND, payload: StockInformation }
export type DmsErrorAction = { type: typeof DMS_ERROR }

export function failEventSource(error): ThunkAction {
	return {
		type: DMS_ERROR,
		error: error
	};
}

export const getVehicleByVin = (vin: string) => {
	return async dispatch => {
		dmsCommunicationService.getVehicleByVin(vin)
			.then(() => {
				return dispatch({type: VEHICLE.REQUEST});
			})
			.catch(e => {
				return dispatch({type: DMS_ERROR, error: e.message});
			});
	};
};

export const searchVehicleByLicensePlate = (licensePlate: string) => {
	return async dispatch => {
		dmsCommunicationService.searchVehicleByLicensePlate(licensePlate)
			.then(() => {
				return dispatch({type: VEHICLE.REQUEST});
			})
			.catch(e => {
				return dispatch({type: DMS_ERROR, error: e.message});
			});
	};
};

export const searchVehicleByCustomerName = (name: string) => {
	return async dispatch => {
		dmsCommunicationService.searchVehicleByCustomerName(name)
			.then(() => {
				return dispatch({type: VEHICLE.REQUEST});
			})
			.catch(e => {
				return dispatch({type: DMS_ERROR, error: e.message});
			});
	};
};
export const searchVehicleByCustomerEmail = (email: string) => {
	return async dispatch => {
		dmsCommunicationService.searchVehicleByCustomerEmail(email)
			.then(() => {
				return dispatch({type: VEHICLE.REQUEST});
			})
			.catch(e => {
				return dispatch({type: DMS_ERROR, error: e.message});
			});
	};
};
export const searchVehicleByCustomerPhone = (phone: string) => {
	return async dispatch => {
		dmsCommunicationService.searchVehicleByCustomerPhone(phone)
			.then(() => {
				return dispatch({type: VEHICLE.REQUEST});
			})
			.catch(e => {
				return dispatch({type: DMS_ERROR, error: e.message});
			});
	};
};

export const getLocalStock = (partNumbers: number[]) => {
	return async dispatch => {
		dmsCommunicationService.getLocalStock(partNumbers)
			.then(() => {
				return dispatch({type: STOCK_INFORMATION.REQUEST});
			})
			.catch(e => {
				return dispatch({type: DMS_ERROR, error: e.message});
			});
	};
};

export const getOrders = () => {
	return async dispatch => {
		dmsCommunicationService.getOrders()
			.then(() => {
				return dispatch({type: DMS_ORDERS.REQUEST});
			})
			.catch(e => {
				return dispatch({type: DMS_ERROR, error: e.message});
			});
	};
};

export const addWatchListItemsToOrder = (dmsOrderNumber, watchListName) => {
	return async dispatch => {
		try {
			const watchList = await CartService.getWatchList(watchListName, "WATCHLIST");
			const items = watchList.cartItems.map(cartItem => {
				return {
					partNumber: cartItem.partNumber,
					quantity: cartItem.amount
				};
			});
			await dmsCommunicationService.addPartsToOrder(dmsOrderNumber, items);
			return dispatch({type: DMS_ORDER_ADD_PARTS.REQUEST});
		} catch (e) {
			return dispatch({type: DMS_ERROR, error: e.message});
		}
	};
};

export const addPartsToOrder = (dmsOrderNumber: string, parts: DmsPart[]) => {
	return async dispatch => {
		try {
			await dmsCommunicationService.addPartsToOrder(dmsOrderNumber, parts);
			return dispatch({type: DMS_ORDER_ADD_PARTS.REQUEST});
		} catch (e) {
			return dispatch({type: DMS_ERROR, error: e.message});
		}
	};
};

export const addPartToOrder = (dmsOrderNumber, partNumber, quantity) => {
	return async dispatch => {
		try {
			await dmsCommunicationService.addPartToOrder(
				dmsOrderNumber,
				partNumber,
				quantity
			);
			return dispatch({type: DMS_ORDER_ADD_PART.REQUEST});
		} catch (e) {
			return dispatch({type: DMS_ERROR, error: e.message});
		}
	};
};

export const updatePartFromOrder = (dmsOrderNumber, partNumber, quantity) => {
	return async dispatch => {
		try {
			await dmsCommunicationService.updatePartFromOrder(
				dmsOrderNumber,
				partNumber,
				quantity
			);
			return dispatch({type: DMS_ORDER_UPDATE_PART.REQUEST});
		} catch (e) {
			return dispatch({type: DMS_ERROR, error: e.message});
		}
	};
};

export const deletePartFromOrder = (dmsOrderNumber, partNumber) => {
	return async dispatch => {
		try {
			await dmsCommunicationService.deletePartFromOrder(dmsOrderNumber, partNumber);
			return dispatch({type: DMS_ORDER_DELETE_PART.REQUEST});
		} catch (e) {
			return dispatch({type: DMS_ERROR, error: e.message});
		}
	};
};
